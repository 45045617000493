@import "./asset/scss/variables.scss";
@import "./asset/scss/mixin.scss";

// * {
//   margin: 0;
//   font-family: $font-ericssonHilda-regular;
//   font-size: 14px;
// }

/* body{
  overflow-y: hidden; 
  overflow-x: hidden;
} */

/*scroll-bar style*/
// ::-webkit-scrollbar {
//   width: 10px;
//   height: 12px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: $background-scroll-color;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #888;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }

.button-needed {
  margin: 5px;
  font-family: "Ericsson Hilda", Helvetica, sans-serif;
}

.light .btn:hover .item {
  color: rgb(255, 255, 255);
}

.light .btn .item:hover {
  color: rgb(255, 255, 255);
}

// global form style
// *{ line-height: normal;}
.light .btn.active {
  background-color: #4e4e4e;
  color: #fff;
  border: 1px solid #4e4e4e;
}

.dark .btn.active {
  background-color: #f2f2f2;
  color: #242424;
  border: 1px solid #f2f2f2;
}

.text-center {
  text-align: center !important;
  justify-content: center;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-wrap {
  word-break: break-all;
}

.w-100 {
  width: 100% !important;
}

input[type="file"] {
  width: auto !important;
}

.text-warning {
  color: #ffc107 !important;
}

.h1,
h1 {
  font-size: 2.5rem;
}

.h5,
h5 {
  font-size: 1.25rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.form-select-css {
  @include form-select-mixin(250px);
}

.form-email-css {
  @include form-select-mixin(350px);
}

.btn {
  border-radius: 0;
}

.from-select-css {
  @include form-select-mixin(176.5px);
}

.report-heading-1 {
  line-height: 1.5em;
  font-size: 14px;
  margin: 10px;
  // font-family: $font-ericssonHilda-bold;
}

.button-needed {
  margin-top: 5px;
}

// .icon-profile {
//   background: url('./asset/Icons/profile.svg');
//   height: 20px;
//   width: 20px;
//   display: block;
//   /* Other styles here */
// }

h4 {
  color: $h4-color;
  // font-family: $font-ericssonHilda-bold;
}

/* graph */
.user-stats-graph {
  position: relative;
  width: 100%;
  height: 450px;
}

// // datepicker style
// .react-datepicker-wrapper {
//   .react-datepicker__input-container {
//     width: 100% !important;
//   }
// }

// .react-datepicker-wrapper {
//   display: block !important;
// }

// button.react-datepicker__close-icon::after {
//   background-color: $button-react-datepicker;
// }

// /* table pagination style */
// .react-bs-table-sizePerPage-dropdown.dropdown {
//   .dropdown-toggle {
//     border-radius: 0 !important;
//     margin-right: 5px;
//     background-color: $background-color;
//     border: 0;
//   }
// }

// .page-item:first-child {
//   .page-link {
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;
//   }
// }

// .page-item:last-child {
//   .page-link {
//     border-top-right-radius: 0;
//     border-bottom-right-radius: 0;
//   }
// }

// .page-item {
//   .page-link {
//     // background-color: $background-color;
//     // color: $font-white-color;
//     @include background-font-color-mixin;
//     border: 0;
//   }

//   .page-link:hover {
//     z-index: 2;
//     border-color: $font-white-color !important;
//     @include background-font-color-mixin(
//       $font-white-color !important,
//       $black-background
//     );
//     // font-family: $font-ericssonHilda-regular;
//     // color: #0d6efd;
//     // background-color: $font-white-color !important;
//     // color: $black-background;
//     //font-weight: 600;
//   }
// }

// .page-item.disabled {
//   .page-link {
//     @include background-font-color-mixin(
//       $background-color,
//       $page-item-disabled-color
//     );
//     border: 0;
//     // background-color: $background-color;
//     // color: $page-item-disabled-color;
//   }
// }

// .react-bootstrap-table-pagination-list {
//   display: flex;
//   justify-content: right;
// }

// .page-item.disabled .page-link {
//   background-color: $background-color;
//   color: $page-item-disabled-color;
//   border: 0;
// }

// .page-item .page-link {
//   background-color: $background-color;
//   color: $font-white-color;
//   border: 0;
// }

// .rangeselector > .button > .selector-text {
//   fill: $black-background !important;
// }

.icon-white-color {
  filter: brightness(0) invert(1);
}

/* sidebar icons */
.sidebar-icons {
  width: 20px;
  /* top: 6px; */
  margin-right: 18px;
  margin-bottom: 3px;
}

.sidebar-icons-actions {
  width: 28px;
  /* top: 6px; */
  margin-right: 13px;
  margin-bottom: 3px;
  margin-left: -3px;
}

/* loading css */
.loading-css {
  width: 32px;
  height: 32px;
  -webkit-animation: 2s ease-in-out infinite rotateAnimation;
  animation: 2s ease-in-out infinite rotateAnimation;
  position: absolute;
  /*  background-image: url('./asset/Icons/loading.svg'); */
}

// @media (prefers-reduced-motion: no-preference) {
//   .loading-logo {
//     animation: loading-logo-spin infinite 1s ease-in-out;
//     /* width: 28px;
//     height: 28px; */
//   }
// }

.loading-container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: 100%;
}

// Apply Button CSS

.apply-css {
  position: relative;
  top: 20px;
  padding: 3px 10px;
}

.error-message-required {
  white-space: nowrap;
}

// @keyframes loading-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }

//   to {
//     transform: rotate(360deg);
//   }
// }

.multiSelectCheckBox {
  position: relative;

  .btnDiv {
    position: absolute;
    height: 265px;
    align-items: end;
    bottom: -280px;
    display: flex;
    z-index: 1;
    width: 184px;
    left: 1px;
    background: #fff;
    justify-content: flex-end;
    padding: 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .multiSelectbtn {
      color: #fff;
      display: flex;
      background-color: #0d6efd;
      width: 60px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 28px;
      border: 0;
      border-radius: 0.25rem;
    }
  }
}

// .module-title {
//   padding: 2px;
//   margin-top: 10px;
//   margin-left: 10px;
//   margin-bottom: 2px;
// }
// .modal {
//   display: block !important;
// }
// .modal-dialog {
//   overflow-y: initial !important;
// }
// .modal-body {
//   max-height: 400px;
//   overflow-y: auto;
// }

// .modal-box-css {
//   height: auto;
//   width: (height/2);
//   align-items: center;
//   position: absolute;
//   left: 0;
//   top: 0;
//   display: none;
//   z-index: 9999;
//   padding: 20px;
//   border-radius: 12px;
//   // font-family: EricssonHilda-Regular;
// }
// .modal-main1-css {
//   min-height: 50vh;
//   height: auto;
//   background-color: #444;
//   width: auto;
//   background-size: cover;
//   padding-top: 5px;
//   padding-left: 10px;
//   padding-right: 10px;
//   color: white;
//   // font-family: EricssonHilda-Regular;
// }
// .modal-header-css {
//   background-color: #444;
//   // font-family: EricssonHilda-Regular;
//   text-align: center;
//   color: white;
//   margin-left: 0px;
// }
// .modal-label-css {
//   // font-family: EricssonHilda-Regular;
//   text-align: left;
//   color: white;
// }
// .modal-listgroup-css {
//   background-color: #444;
//   // font-family: EricssonHilda-Regular;
//   text-align: left;
//   color: white;
// }
// .modal-list-item-css {
//   background-color: #444;
//   // font-family: EricssonHilda-Regular;
//   text-align: left;
//   color: white;
// }
.SiderBarLoader {
  .loading-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
  }
}

.filters-fixed-css {
  position: sticky;
  z-index: 1000;
  width: 100%;
  background-color: #181818;
  top: 38.8px;

  .hstack {
    align-items: flex-start;
  }
}

@media screen and (max-width: 992px) {
  .filters-fixed-css {
    position: relative;
    z-index: 2;
    top: 0;
  }
}

// .table tbody > tr:hover {
//   background-color: #4e4e4e;
// }

.table.dashed tr:not(:last-child) td {
  border-bottom-style: dashed;
}

// color: #17c917
.table {
  [data-key="ProgressData"] {
    width: 200px;
  }
  tbody{
    .p-16{padding: 16px !important;}
  }
}

.dark .table .alertSpace {
  width: 20px;
  display: inline-block;
}

.icon.icon-email.emailNotSent,
.icon.icon-notification.notificationNotSent,
.icon.icon-notification.notificationNotSent {
  color: #fd4343;
}

// .icon.icon-email.emailSent, .icon.icon-notification.notificationSent { color: #fd4343;}
.icon.icon-email.emailNotApply,
.icon.icon-notification.notificationNotApply,
.icon.icon-message-contact-us.notificationNotApply {
  color: #999;
}

.progress-bar progress + span,
.progressbar progress + span {
  display: inline;
}

.table .cell-split {
  vertical-align: middle;
}

.tile .modal-header-css {
  background-color: transparent;
}

.BeaconConfiguration {
  .customAlign {
    display: flex;
    align-items: flex-end;
    height: 57px;
  }

  .customInput {
    width: 100%;
  }

  .telno {
    display: flex;
    flex-wrap: wrap;

    .countryCode {
      max-width: 125px;
      margin-right: 5px;
    }

    .dummayCountry {
      max-width: 125px;
      height: 32px;
      border-color: #767676 !important;
      padding: 5px 28px 4px 4px !important;
    }

    .phoneNoCont {
      width: calc(100% - 138px);
    }
  }
}

.user_management_fields {
  padding: 5px 28px 4px 4px !important;
}

.loneWorkerManagerList {
  .select {
    &.ManagerList,
    &.LoneWorkerList {
      width: 100%;
      max-width: 100%;
    }

    .validation-msg {
      position: absolute;
      top: -25px;
      right: 0;
    }
  }

  .tooltip {
    width: 100%;

    .btn {
      width: 100%;
    }
  }
}
.Notification1 {
  .positionText {
    text-align: center;
  }
  .btnAlign {
    float: right;
  }
  width: 30% !important;
}

.select {
  &.Notification {
    width: 100%;
    max-width: 100%;
  }
}

.eds-custom-select-dropdown .select {
  width: 100% !important;

  .options_container {
    display: none !important;
  }
}

.content-body-css,
.content-body2-css {
  position: relative;
  min-height: 1000px;
  margin-top: 15px;
}

.light {
  .appnav {
    .item {
      .icon-white-color {
        filter: contrast(0.5);
      }

      &.active {
        .icon-white-color {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}

.sidebar-row {
  .breadcrumb {
    .link {
      text-transform: capitalize;
    }
  }
}

.homeLink {
  text-decoration: none !important;
  color: $font-white-color !important;
  display: flex;
  align-items: center;
}

.cstAplyfilDiv {
  display: flex;
  align-items: flex-end;

  @media screen and (max-width: 991px) {
    justify-content: center;
  }
}

.formlist {
  display: flex;
  align-items: flex-end;
  margin-right: 20px;
  width: 100%;
}
.md-mt-bs {
  @media screen and (max-width: 991px) {
    margin-top: 8px;
  }
}

.site-walk-graph {
  width: 100%;
}

.filterDiv {
  .md-mt-bs {
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10) {
      @media screen and (min-width: 991px) {
        margin-top: 8px;
      }
    }
  }
}

.dark {
  .stickyHeader {
    table-layout: fixed;

    thead {
      tr {
        position: sticky;
        top: 0;
        background: #242424;
        z-index: 2;

        th {
          position: relative;

          &::before {
            content: "";
            bottom: -1px;
            position: absolute;
            width: 100%;
            height: 1px;
            background: #fafafa;
            left: 0;
          }
        }
      }
    }
  }
}

.light {
  .stickyHeader {
    table-layout: fixed;

    thead {
      tr {
        position: sticky;
        top: 0;
        background: #fafafa;
        z-index: 2;

        th {
          position: relative;

          &::before {
            content: "";
            bottom: -1px;
            position: absolute;
            width: 100%;
            height: 1px;
            background: #242424;
            left: 0;
          }
        }
      }
    }
  }
}

.colorRed {
  background: "#dc2d37";
}

.dark .redColor.btn:disabled,
.light .redColor.btn:disabled {
  background: #dc2d37;
  border-color: #dc2d37;
}

.dark,
.light {
  .btn.success {
    color: #fff;
    background-color: #288964;
    border: 1px solid #288964;
  }

  .btn.success:hover:not(:disabled) {
    color: #fff;
    background-color: #288964;
    border: 1px solid #288964;
  }

  .btn.success:active:not(:disabled) {
    color: #fff;
    background-color: #09442d;
    border: 1px solid #09442d;
    transition: none;
  }
}

.JHA-Reports {
  th[data-key="Download"],
  th[data-key="Total time (mins)"],
  th[data-key="Code Red"],
  th[data-key="Status"],
  th[data-key="Approval"],
  th[data-key="Reason"],
  th[data-key="Reject"],
  th[data-key="Project ID"],
  th[data-key="Action"] {
    text-align: center !important;
  }

  td {
    .text-center {
      text-align: center !important;
    }

    .text-right {
      text-align: right !important;
    }
  }

  button {
    i {
      margin-right: 0 !important;
    }
  }

  .checkVersionTD {
    text-align: center;

    .viewSubReport {
      min-width: 20px !important;
      border: 1px transparent !important;
    }
  }
}

.EHS-reports-table {
  .checkVersionTD {
    text-align: center;
    .viewSubReport {
      min-width: 20px !important;
      border: 1px transparent !important;
    }
  }
}

#loneworkerTable {
  th[data-key="Timer in-Min"],
  th[data-key="Status"],
  th[data-key="Extended Time"],
  th[data-key="Acknowledgement"],
  th[data-key="Alert"] {
    text-align: center !important;
  }
}

#Crew-Participation-Count {
  th[data-key="Participation_Count"] {
    text-align: center !important;
  }
}

.approvalReject {
  .commentBox {
    width: 100%;
  }
}

.approvalReject,
.incidentPop {
  .content {
    width: 685px;
  }
}

#reportwithtotaltimetaken-table {
  .custom-table-body-1 {
    word-break: break-all;
  }
}

.filterDiv {
  z-index: 3;

  @media only screen and (min-width: 991px) {
    position: sticky;
    top: 0;
  }
}

.fileDiv {
  .fileUpload {
    position: relative;

    &:before {
      display: flex;
      justify-content: center;
      align-items: center;
      content: "Choose File";
      background: #0077db;
      padding: 7px;
      min-width: 75px;
      padding: 5px 12px 4px;
      border-radius: 3px;
      font-size: 14px;
      line-height: 1.5;
      font-family: "Ericsson Hilda", Helvetica, sans-serif;
      color: #fff;
      position: absolute;
      left: 0;
      bottom: 3px;
    }
  }

  input[type="file"] {
    padding-left: 7px !important;
  }
}

.btn {
  text-align: center;
}

.dark,
.light {
  .syspanel {
    height: calc(100vh - 96px);
  }

  @media only screen and (min-width: 30em) {
    .syspanel.hidden {
      height: calc(100vh - 96px);
    }
  }

  .appbar {
    .title.open-menu {
      display: flex;
      align-items: center;
    }
  }

  main {
    .app {
      .appbody {
        height: calc(100vh - (50px + 50px + 50px)) !important;
      }
    }
  }

  .tile {
    padding: 16px !important;
  }

  .calendar {
    table.body {
      tbody {
        tr {
          * {
            line-height: 28px;
          }
        }
      }
    }
  }

  // .dialog.fullscreen > .content {
  //   overflow: auto !important;
  //   width: calc(100% - 48px);
  //   height: calc(100% - 48px);
  // }
  // .dialog.fullscreen > .content .body {
  //   overflow: auto !important;
  // }

  .select {
    .options-list {
      display: none;
    }
  }

  .tooltip {
    opacity: 1;
    padding: 0;
    z-index: 1;
    display: inline-block;
  }

  .signin {
    .formDiv,
    form {
      flex: 2;
      align-self: center;
      width: 280px;

      &.hidden {
        display: none;
      }

      .field {
        margin-bottom: 16px;
      }

      input:not([type="checkbox"]) {
        font-weight: 300;
        width: 100%;
      }

      input:not([type="checkbox"])::-webkit-input-placeholder {
        color: #8b8b8b;
      }

      input:not([type="checkbox"])::-moz-placeholder {
        color: #8b8b8b;
      }

      input:not([type="checkbox"]):-ms-input-placeholder {
        color: #8b8b8b;
      }

      input:not([type="checkbox"])::-ms-input-placeholder {
        color: #8b8b8b;
      }

      input:not([type="checkbox"])::placeholder {
        color: #8b8b8b;
      }

      input:not([type="checkbox"]).validation:invalid {
        border-color: #dc2d37;
      }

      input[type="checkbox"] + label {
        margin: 16px 0 32px;
      }

      input[type="checkbox"]:focus::before {
        outline: #0077db solid 1px;
      }

      input[type="checkbox"]:not(:checked):not(:indeterminate):not(:disabled)
        + label::before {
        content: " ";
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: bottom;
      }

      input[type="checkbox"]:checked:disabled + label::before {
        content: " ";
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: bottom;
      }

      input[type="checkbox"]:checked:not(:disabled):hover + label::before,
      input[type="checkbox"]:not(:disabled):hover + label::before {
        content: " ";
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: bottom;
      }

      .btn {
        width: 100%;
        margin-bottom: 32px;
      }
    }
  }

  input.validation + .validation-msg::before {
    color: #dc2d37;
  }

  .progress-bar,
  .progressbar {
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    display: flex;
    text-align: center;

    progress + span {
      margin-left: 4px;
      display: inline;
      font-size: 12px;
      min-width: 78px;
    }

    span.unit {
      font-size: 12px;
    }

    &.sixtyComplete {
      progress {
        color: #d7d717;

        &.buffer {
          opacity: 0.3;
        }

        &::-webkit-progress-bar {
          background: #767676;
        }

        &::-webkit-progress-bar.buffer {
          margin-right: calc(44px - 100%);
          opacity: 0.3;
        }

        &::-webkit-progress-value {
          background-color: #d7d717;
        }
      }

      progress[role][aria-valuenow] {
        background: #767676 !important;

        &.buffer {
          margin-right: calc(44px - 100%);
          opacity: 0.3;
        }
      }

      progress[aria-valuenow]::before,
      progress[value]::-moz-progress-bar,
      progress[value]::-webkit-progress-value {
        background: #d7d717;
      }

      progress[aria-valuenow]::before.buffer,
      progress[value]::-moz-progress-bar.buffer,
      progress[value]::-webkit-progress-value.buffer {
        margin-right: calc(44px - 100%);
        opacity: 0.3;
      }
    }

    &.inProcess {
      progress {
        color: #17c917;
        background: #767676 !important;

        &.buffer {
          opacity: 0.3;
          margin-right: calc(44px - 100%);
          opacity: 0.3;
        }

        &[role][aria-valuenow] {
          background: #767676 !important;
          margin-right: calc(44px - 100%);
          opacity: 0.3;
        }

        &::-webkit-progress-bar {
          background: #767676;

          &.buffer {
            margin-right: calc(44px - 100%);
            opacity: 0.3;
          }
        }

        &::-webkit-progress-value {
          background-color: #17c917;
        }

        &[aria-valuenow]::before,
        &[value]::-moz-progress-bar,
        &[aria-valuenow]::before {
          background: #17c917;

          &.buffer {
            margin-right: calc(44px - 100%);
            opacity: 0.3;
          }
        }
      }
    }
  }

  .tree {
    .title,
    .item {
      align-items: center;
    }
  }
}

.light {
  .signin {
    a.subtle-link {
      color: #242424;
      border-bottom: 1px dashed #242424;
    }
  }
}

.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover,
.dark input:-webkit-autofill:focus,
.dark textarea:-webkit-autofill,
.dark textarea:-webkit-autofill:hover,
.dark textarea:-webkit-autofill:focus {
  background-color: #181818;
  -webkit-text-fill-color: #f2f2f2;
  color: #f2f2f2;
  -webkit-box-shadow: 0 0 0px 1000px #181818 inset !important;
  transition: background-color 5000s ease-in-out;
}

.light input:-webkit-autofill,
.light input:-webkit-autofill:hover,
.light input:-webkit-autofill:focus,
.light textarea:-webkit-autofill,
.light textarea:-webkit-autofill:hover,
.light textarea:-webkit-autofill:focus {
  background-color: #fff;
  -webkit-text-fill-color: #242424;
  color: #242424;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  transition: background-color 5000s ease-in-out;
}

.mdSize {
  .body {
    width: 600px !important;
  }
}

@media only screen and (max-width: 991px) {
  .horizonatalScroll {
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    tbody,
    thead {
      min-width: 1200px;
      display: flex;
      flex-direction: column;

      tr {
        flex-wrap: wrap;
        display: flex;

        &.details-row {
          td.cell-details {
            width: calc(100% - 50px) !important;
            display: flex;
            flex-wrap: wrap;

            .mb-bs,
            div {
              width: 100%;
            }
          }
        }

        &.no-data-row {
          td {
            width: 100% !important;
            justify-content: center;
          }
        }
      }

      th,
      td {
        display: flex;
        align-items: center;
      }
    }
  }

  #JHA-Reports {
    tbody,
    thead {
      th,
      td {
        width: calc(100% / 11);
      }
    }
  }

  #SupplierList {
    tbody,
    thead {
      th,
      td {
        width: calc((100% - 32px) / 7);

        &.cell-expand {
          width: 32px;
        }
      }
    }
  }

  #reportwithtotaltimetaken-table {
    tbody,
    thead {
      th,
      td {
        width: calc((100% - 32px) / 14);

        &.cell-expand {
          width: 32px;
        }
      }
    }
  }

  #action-items-table {
    tbody,
    thead {
      th,
      td {
        width: calc((100% - 32px) / 9);

        &.cell-expand {
          width: 32px;
        }
      }
    }
  }

  #loneworkerTable {
    tbody,
    thead {
      th,
      td {
        width: calc((100% - 32px) / 8);

        &.cell-expand {
          width: 32px;
        }
      }
    }
  }

  #actions-example {
    tbody,
    thead {
      th,
      td {
        width: calc((100% - 32px) / 4);

        &.cell-expand {
          width: 32px;
        }
      }
    }
  }

  #Notification-table {
    tbody,
    thead {
      th,
      td {
        width: calc((100% - 32px) / 3);

        &.cell-expand {
          width: 32px;
        }
      }
    }
  }

  #safetydialogueTopics-table {
    tbody,
    thead {
      th,
      td {
        width: calc(100% / 4);
      }
    }
  }

  #displayUser {
    tbody,
    thead {
      th,
      td {
        width: calc((100% - 100px) / 6);
        // &.cell-actions{width: 100px;}
      }
    }
  }

  #HazardIDTable {
    tbody,
    thead {
      th,
      td {
        width: calc((100% - 32px) / 8);

        &.cell-expand {
          width: 32px;
        }
      }
    }
  }

  #tableId {
    tbody,
    thead {
      th,
      td {
        width: calc(100% / 7);
      }
    }
  }
}

.light {
  .light {
    .hero {
      background: linear-gradient(-90deg, #0082f0, #1174e6);
    }
  }

  .hero {
    background: #1174e6;
    padding: 40px;
    color: #fff;

    .column {
      margin: 0;
    }

    .hero-left {
      padding: 0;
      margin-bottom: 0;

      h1 {
        padding-top: 0;
        padding-bottom: 30px;
        font-size: 3em;
        text-transform: none;
        margin: 0;
        font-weight: 300;
      }

      .description {
        font-size: 1.2em;
        padding-bottom: 20px;
        color: #fff;
        font-weight: 100;
      }

      .p-button {
        flex: 0 0 auto;
      }

      .btn {
        color: #fff;
        border-color: #fff;
        margin: 8px 8px 0 0;
        display: inline-block;

        &:hover {
          background: #fff;
          color: #1174e6;
          border-color: #fff;
        }
      }
    }
  }

  .dark {
    .hero {
      background: #1174e6;
    }
  }
}

.dark {
  .btn.color-red {
    color: #dc2d37 !important;
  }

  .btn.color-green {
    color: #288964 !important;
  }

  .light {
    .hero {
      background: linear-gradient(-90deg, #0082f0, #1174e6);
    }
  }

  .dark {
    .hero {
      background: #0077db;
    }
  }

  .hero {
    background: #0077db;
    padding: 40px;
    color: #fff;

    .column {
      margin: 0;
    }

    .hero-left {
      padding: 0;
      margin-bottom: 0;

      h1 {
        padding-top: 0;
        padding-bottom: 30px;
        font-size: 3em;
        text-transform: none;
        margin: 0;
        font-weight: 300;
      }

      .description {
        font-size: 1.2em;
        padding-bottom: 20px;
        color: #fff;
        font-weight: 100;
      }

      .p-button {
        flex: 0 0 auto;
      }

      .btn {
        color: #fff;
        border-color: #fff;
        margin: 8px 8px 0 0;
        display: inline-block;

        &:hover {
          background: #fff;
          color: #0077db;
          border-color: #fff;
        }
      }
    }
  }
}

.table tbody tr .statusHandler {
  width: 70px;
}

.list-group {
  padding-left: 0;
  max-height: 150px;
  overflow-y: auto;

  .list-group-item {
    list-style-type: none;
    padding: 7px 10px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-bottom: 0;

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    }
  }
}

.filterBtn {
  display: flex;
  width: 100%;

  .switch {
    width: auto;
    margin-right: 10px;

    &.overrunSession.active {
      .ball {
        background-color: #e66e19;
        border-color: #e66e19;
      }
    }

    &:hover {
      &.overrunSession.active {
        .ball {
          background-color: #e66e19 !important;
          border-color: #e66e19 !important;
        }
      }
    }

    &.activeSession.active {
      .ball {
        background-color: #0077db;
        border-color: #0077db;
      }
    }

    &:hover {
      &.activeSession.active {
        .ball {
          background-color: #0077db !important;
          border-color: #0077db !important;
        }
      }
    }

    &.panicAlert.active {
      .ball {
        background-color: #dc2d37;
        border-color: #dc2d37;
      }
    }

    &:hover {
      &.panicAlert.active {
        .ball {
          background-color: #dc2d37 !important;
          border-color: #dc2d37 !important;
        }
      }
    }

    &.completedSession.active {
      .ball {
        background-color: #30a697;
        border-color: #30a697;
      }
    }

    &:hover {
      &.completedSession.active {
        .ball {
          background-color: #30a697 !important;
          border-color: #30a697 !important;
        }
      }
    }

    &.missCheckIn.active {
      .ball {
        background-color: #fad22d;
        border-color: #fad22d;
      }
    }

    &:hover {
      &.missCheckIn.active {
        .ball {
          background-color: #fad22d !important;
          border-color: #fad22d !important;
        }
      }
    }
  }
}

.modal-md {
  .content {
    width: 991px !important;
    @media only screen and (max-width: 991px) {
      width: 100% !important;
    }
  }
}
.modal-sm {
  .content {
    width: 751px !important;
    @media only screen and (max-width: 991px) {
      width: 100% !important;
    }
  }
}
.modal-xs {
  .content {
    width: 480px !important;
    @media only screen and (max-width: 991px) {
      width: 100% !important;
    }
  }
}

.roleTable {
  .history {
    display: flex;
    flex-wrap: wrap;
  }
}
.appbar {
  .title {
    .title-name {
      text-transform: capitalize;
    }
  }
}

.tabs {
  .titles {
    text-align: right;
    .title:focus {
      outline: none !important;
    }
  }
  &.supplierViewMore{
    .titles {
      text-align: left;
      .title:focus {
        outline: none !important;
      }
    }
  }
}

.dialog.md > .content {
  min-width: 700px !important;
  max-width: 80% !important;
}

@media screen and (max-width: 632px) {
  .dialog.md > .content {
    min-width: auto !important;
    max-width: none !important;
  }
}

.eds-cards-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px 16px;
  .card {
    position: relative;
    @media (max-width: 760px) {margin: 0;}
    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      .title {
        font-weight: 700 !important;
        font-size: 18px !important;
      }
      .subtitleMB{
        &:first-child{margin-bottom: 15px;}
      }
      i {
        margin-left: 16px !important;
        font-weight: 700 !important;
      }
    }
  }
  .card:hover {
    border: 1px solid #0077db;
    position: relative;
  }
}
@media (max-width: 1200px) {
  .eds-cards-list {
    grid-template-columns: 1fr 1fr;
  }
}
.defaultwidth {
  width: 30% !important;
  background-color: #333333 !important;
}

.light .defaultwidth {
  width: 30% !important;
  background-color: #f2f2f2 !important;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  display: block;
}

div#btnGroupH-Report {
  display: flex;
  button {
    margin: 0 2px;
    min-width: 33px !important;
  }
  .tooltip:not(:first-child) {
    button {
      border-radius: 0;
    }
  }
  .border {
    border: 1px solid #242424;
    margin: 0 2px;
    width: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
  }
  .tooltip:last-child {
    button {
      border-radius: 0 3px 3px 0;
    }
  }
}

.carousel-container {
  width: 100%;
  min-height: 400px;
  min-width: 550px;
}

.carousel-slide {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cs-content {
  width: 100%;
  height: 100%;
}

.cs-content-full {
  height: 100%;
  padding: 50px 80px;
}

.carousel-slide p {
  font-size: 12px;
}

.cs-title {
  font-size: 16px;
  display: inline-block;
  font-weight: 500;
  line-height: 1.5em;
  margin-bottom: 8px;
}

.cs-img {
  width: 100% !important;
  height: 100% !important;
  background-size: 100% !important;
  background-repeat: no-repeat;
}
.sysbar {
  .product,
  .acronym {
    margin-left: 9px !important;
  }
}

@media screen and (min-width: 992px) {
  .dark .tile {
    .header.stickyTileHeader,
    .title.stickyTileHeader {
      position: sticky;
      top: 0;
      background: #242424;
      padding-top: 16px;
      z-index: 1;
    }
  }

  .light .tile {
    .header.stickyTileHeader,
    .title.stickyTileHeader {
      position: sticky;
      top: 0;
      background: #fafafa;
      padding-top: 16px;
      z-index: 1;
    }
  }
}

@media screen and (min-width: 992px) {
  .dark .tile {
    .header.stickyTileHeader,
    .title.stickyTileHeader {
      position: sticky;
      top: 0;
      background: #242424;
      padding-top: 16px;
      z-index: 1;
    }
  }

  .light .tile {
    .header.stickyTileHeader,
    .title.stickyTileHeader {
      position: sticky;
      top: 0;
      background: #fafafa;
      padding-top: 16px;
      z-index: 1;
    }
  }
}

@media (min-width: 768px) {
  .eds-form.horizontal .form-body {
    max-width: 100%;
    .form-group {
      width: 100%;
      display: flex;
      flex: auto;
    }
    .form-element {
      display: inline-block;
      vertical-align: top;
      margin-top: 0;
      margin-right: 8px;
      width: 100%;
    }
  }
}

.phonenumber {
  display: flex;
  .select {
    flex: 0 0 35%;
    margin-right: 4px;
  }
  .phone {
    flex: 0 0 65%;
  }
}

.light .page-hr {
  border-color: #ebebeb;
  border-style: solid none none;
  border-width: 1px;
}
.dark .page-hr {
  border-color: #181818;
  border-style: solid none none;
  border-width: 1px;
}
.sysbar {
  .product,
  .acronym {
    margin-left: 6px !important;
  }
}

.dark .btn.green {
  color: #fff;
  background-color: #288964;
  border: 1px solid #288964;
}
.dark .btn.green:hover:not(:disabled) {
  color: #fff;
  background-color: #288964;
  border: 1px solid #288964;
}
.dark .btn.green:active:not(:disabled) {
  color: #fff;
  background-color: #288964;
  border: 1px solid #288964;
  -webkit-transition: none;
  transition: none;
}

.light .btn.green {
  color: #fff;
  background-color: #329864;
  border: 1px solid #329864;
}
.light .btn.green:hover:not(:disabled) {
  color: #fff;
  background-color: #329864;
  border: 1px solid #329864;
}
.light .btn.green:active:not(:disabled) {
  color: #fff;
  background-color: #329864;
  border: 1px solid #329864;
  -webkit-transition: none;
  transition: none;
}

.certificateThumbnail {
  position: static !important;

  img {
    border: 1px solid #ddd; /* Gray border */
    border-radius: 4px; /* Rounded border */
    width: 30px;
    height: 30px;
    margin-left: 5px;
  }

  /* Add a hover effect (blue shadow) */
  img:hover {
    box-shadow: 0 0 2px 1px #619de3;
  }
}

.input-action {
  display: flex;
  gap: 2px;
}

.dark input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.disclaimer-text {
  font-family: "Ericsson Hilda";
  font-weight: 900;
}
.featureCome{display: flex; justify-content: center; align-items: center;
  p{margin-left: 5px; font-weight: 500;}
}

#root{
  > .SiderBarLoader{
    > .loading-container{
      .loading{color: #fff;}
    }
  }
}
 
.offWhite{color: #888; align-items: center;}
.disclaimer-text{white-space: nowrap;}
.flowChart{
   > div:first-child, > div:nth-child(2){display: none;}
  .node-container{
    .subtitle{text-align: center; margin-top: 14px !important;}
  }
  .content{
    .description{font-weight: 500; font-size: 16px;}
  }
}
.appLinks{
  .subTitle{margin-top: 10px; margin-bottom: 0;}
  .content{display: flex; align-items: center; margin-left: -10px;
    .appLink{display: flex; outline: none;
      .appImg{width: 160px;
        @media (max-width: 480px) {width: 150px}
      }
      .iosApp{ width:128px;
        @media (max-width: 480px) {width: 120px}
      }
    }
  }
}
#incidents-edstable{
  .incident-Img{width: 50px; height: 50px;}
}
.dialog.show {
    &.RoleInfo{
    z-index: 101;
  }
}
#addnewUser-dialog{
  i.roleInfo { position: absolute; margin-left: 15px;}
}
.ai-imagesUpload{
  .tile{
    .header{padding-bottom: 10px;}
  }
  .card{
    &.drag-and-drop{ margin: 8px 0;
      .drag-and-drop-area{padding: 10px 32px;
        i.icon-upload{font-size: 24px;}
      }
      input[type=file]{width: 0 !important;}
    }
    .header+.content{padding-top: 0;} 
  }
  .uploadedImages{ 
    .card{ margin: 8px 0;cursor: auto;
      .content{padding: 15px;}
      .header{padding: 0 15px;}
    }
  }
  .customTable{
    &::after{display: none;}
    tr{
      &:not(:last-child){
        td{border: 0; border-bottom: 0; border-bottom-style: none;}
      }
      td{border: 0; border-bottom-style: none !important; vertical-align: top;
        .mb-10{margin-bottom: 5px;}
      }

    }
  }
  .tableContainer{
    @media (max-width: 991px) { 
       width: 100%;
      overflow-x: auto}
      .hazardsImtigationTable{
        @media (max-width: 991px) { width: 1000px;}
      }
  }
  .bulkUpload{
    .form-element{margin-bottom: 20px;
      input[type="text"], .select{ max-width: 70%; width: 70%;}
    }
    .uploadMsg{ width: 100%; margin: 15px; text-align: center;}
  }
}
#addnewUser-dialog{
  .content{
    @media (max-width: 991px) { width: 90%;}
  }
  .phoneCode{
    .phoneCountryCode{display: flex;
      div#eds-select-dropdown-custom-countryCode, div#eds-select-dropdown-custom-EmergencyPhoneCCode{max-width: 100px;}
      input#phoneNo, input#alternatephoneNo {width: calc(100% - 110px);margin-right: 0; margin-left: 8px; }
    }
  }
}
.HazardID{
  .actionItem{
    &.btn {
      .icon{margin-right: 0;}
    }
  }
  .createHazardEle{min-height: 400px;
    .formEle{
      .form-element{margin-bottom: 15px;
          input[type="text"], .select{width: 70%; max-width: 70%;
            @media (max-width: 991px) {
              width: 100%; max-width: 100%;
            } 
          }
      }
    }
    .card{ margin: 8px 0;}
    .centerLoader{display: flex; flex: 1;}
  }
  .disclaimer{margin-top: 10px;}
}

.ActivePanicList{ position: relative; overflow: hidden;
  .tile{transition: all .25s ease-in-out;
    &.currentActive, &.currentPanic{
      @media (max-width: 991px) { order: 2}
    }
  }
  .ActivePanicMetrics{
    @media (max-width: 991px) { order: 0}
    .column{
      @media (max-width: 991px) { display: flex; }
      .card{ padding: 8px; width: calc(100% - 16px);
        .data-title{font-size: 14px; height: 35px; }
        .data-value{font-size: 22px; height: auto;}
        .data-details:empty{display: none;}
        .data-details{height: auto; font-size: 12px;}
      }
    } 
  }
  .activePanicMapcontainer{ position: absolute; right: -20%;  width: 100%; top: 0;  height: calc(100% - 3px);
    .title { width: 100%; position: relative;
      .right{ position: absolute; right: 0; top: 0;}
    }
    .column{
      height: 100%;
    }
    @media (min-width: 992px) {
      &.open{ right: 0; }
    }
      @media (max-width: 991px) { order: 1;
        &.close{display: none; }
        &.open{  display: inline-block; position: static;}
      }
      #activePanicMap{height: 100% !important;
        @media (max-width: 991px) { height: 300px !important;}
      }
    }
    .table{
      tbody{
        tr{
          &:not(.no-data-row){cursor: pointer;}
        }
      }
    }
}

.loneWorker{
  .table{
    th[data-key="Status"], th[data-key="Acknowledgement"], th[data-key="Extended Time"], th[data-key="Timer in-Min"], th[data-key="Alert"]{text-align: center;}
  }
  .iconTitle{display: flex; align-items: center;
    span{margin-left: 12px; border-left: 1px solid; padding-left: 12px; height: 14px; display: flex; align-items: center;}
  }
  .Box{
    .totalCount{ 
      &.rightBorder{border-right: 1px solid;}
      .count{font-size: 40px; display: flex; justify-self: center; align-items: flex-start; height: 80px;
        &.color-yellow{text-shadow: 0px 1px 0px #686868;}
      }
      .countTitle{font-size: 14px; text-align: center;
        &.color-yellow{text-shadow: 0px 1px 0px #686868;}
      }
    }
  }
   .geographical-view-chart{
    .eds-cluster .eds-circle-outer svg.eds-circle-outline .eds-circle-arc{stroke: #30a697;}
    .eds-cluster .eds-circle-outer svg.eds-circle-outline .eds-circle-arc.active{ stroke: #1174e6;}
    .eds-marker .eds-circle-wrapper .eds-circle-outer.active.maintenance{background-color: #30a697; border-color: #30a697;}
    .eds-small-marker .eds-circle-outer.active.maintenance{border-color: #30a697;}
    .eds-marker .eds-circle-wrapper div.eds-circle-outer.active.maintenance + p + div .eds-alert::before{    
      content: "\e997";
      color: #329864;
      font-size: 14px;
      margin-right: 4px;
      font-family: "Ericsson Icons" !important;
      vertical-align: middle;
      speak: none;
      font-style: normal;}
      
      .eds-marker .eds-circle-wrapper .eds-alerts .eds-alert::before{    
        content: "\e994";
        color: #1174e6;
        font-size: 14px;
        margin-right: 4px;
        font-family: "Ericsson Icons" !important;
        vertical-align: middle;
        speak: none;
        font-style: normal;
      }
  }


  .accordion{
    .accordion-item{
      .header{padding-bottom: 0;}
      .content{padding: 0;}
    }
  }
}

.HazardIDViewDetails{
  .StaticInfoDetails{ display: flex; flex-wrap: wrap; 
    .dataInfo{display: flex; font-size: 14px; margin: 0 5px 20px 5px;
      @media (min-width: 1200px) { width: calc(100% / 4 - 10px)}
      @media (min-width: 1600px) { width: calc(100% / 6 - 10px)}
      @media (max-width: 991px) { width: calc(100% / 2 - 10px)}
      @media (max-width: 756px) { width: calc(100% / 1 - 10px)}
      .dataLabel{ font-weight: 600; padding-right: 8px;}
    }
  }
  .table{
    tr{
      td.cell-split{padding: 16px; 
        @media (max-width: 756px) { word-break: break-all;}
        .viewLink{margin-bottom: 5px; width: 100%; display: flex;}
      }
      td{text-transform: capitalize;}
    }  
  }
  .tabs{display: flex; flex-wrap: wrap;
    &.left{
      .titles{text-align: left; width: calc(100% - 235px);
        @media (max-width: 756px) { order: 1; width: 100%;}
      }
      .actionInfo{width: 235px; display: flex; justify-content: space-between;
        @media (max-width: 756px) { order: 0; width: 100%; justify-content: flex-end; margin-bottom: 10px;
          a.btn{margin-left: 8px;}
        }
      }
      .contents{width: 100%;
        @media (max-width: 756px) { order: 2;}
      }
    }
  }
  .actionInfo{
    .dropdown{
      .menu{right: 0; padding: 0;}
    }
  }
}

.hazardsLargImg{
  .static{ width: 100%; flex-flow: column;
    .dataInfo{display: flex; margin-bottom: 10px;
      .dataLabel{font-weight: 600; padding-right: 5px;}
    }
  }
  .lazy-load-image-background{width: 100%; display: flex; justify-content: center; align-items: center;
    img{max-width: 100%;
      &.hide{display: none;}
    }
  }
  .hoverLoader{position: absolute; z-index: 1; display: flex; justify-content: center; align-items: center; width: 100%; }
}

#HazardIDTable{
  .history{margin-bottom: 13px;
    .sub-History{margin-bottom: 5px; text-transform: capitalize;}
  }
}